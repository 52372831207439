<template>
  <h1>Usuarios</h1>
</template>

<script>
export default {
  name: "Usuarios",
};
</script>

<style></style>
